import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AdsGETApiAuth, AdsPOSTApi, AdsPUTApi, AdsPUTApiToken } from "../../../../Constant/api";
import { slugs } from "../../../../Constant/slugs";
import { AlertComponent } from "../../../AlertComponent";
import { CustomButton } from "../../../CustomButtom";
import ImageUpload from "../../../ImageUpload";
import { login } from '../../../../redux/action';
const studentRoles = ["subscriber", "student"]
export default function FormComponent(props) {
  const { FetchData } = props;
  const history = useHistory()
  const [ID, setID] = React.useState(FetchData.id)
  const Login = useSelector((state) => state.LoginState);

  const [author, setAuthor] = React.useState(FetchData.author)
  const [Title, setTitle] = React.useState(FetchData.title.rendered)
  const [UserRole, setUserRole] = React.useState(FetchData.meta.user_role)
  const [ProfilePicture, setProfilePicture] = React.useState(FetchData.meta.user_profile_picture)
  const [SubscriptionStart, setSubscriptionStart] = React.useState(FetchData.meta.user_subscription_start)
  const [SubscriptionEnd, setSubscriptionEnd] = React.useState(FetchData.meta.user_subscription_end)
  const [PhoneNumber, setPhoneNumber] = React.useState(FetchData.meta.user_phone_number)
  const [VerificationStatus, setVerificationStatus] = React.useState(FetchData.meta.verification_status)
  const [userID, setUserID] = React.useState(FetchData.meta.user_id)
  const [userName, setUserName] = React.useState(FetchData.meta.user_name)
  const [Password, setPassword] = React.useState(FetchData.meta.class_code)
  const [userEmail, setUserEmail] = React.useState(FetchData.meta.user_email)
  const [userVerificationHistory, setUserVerificationHistory] = React.useState(FetchData.meta.user_verification_history)
  const [batchName, setBatchName] = React.useState(FetchData.meta.user_featured_exam)
  const [batchID, setBatchID] = React.useState(FetchData.meta.user_featured_exam_slug)
  const [MainSlug, setMainSlug] = React.useState(FetchData.meta.user_featured_exam_main_slug)
  const [MainSlugT, setMainSlugT] = React.useState(FetchData.meta.user_featured_exam_main_slug)
  const [batchModal, setBatchModal] = React.useState(false)
  const [batchList, setBatchList] = React.useState([])
  const [ExamSelect, setExamSelect] = React.useState([])
  const [serachName, setSearchName] = React.useState("")
  const [ExamArray, setExamArray] = React.useState([])
  const [ExamArray1, setExamArray1] = React.useState([])
  const [ExamArray2, setExamArray2] = React.useState([])

  const [userVerificationHistoryModal, setUserVerificationHistoryModal] = React.useState(false)
  const [editKey, setEditKey] = React.useState(FetchData.meta.user_verification_history)
  const [EditScreenShot, setEditScreenShot] = React.useState("")
  const [EditStartDate, setEditStartDate] = React.useState("")
  const [EditEndDate, setEditEndDate] = React.useState("")
  const [EditAmountPaid, setEditAmountPaid] = React.useState("")
  const [EditRemarks1, setEditRemarks1] = React.useState("")
  const [EditRemarks2, setEditRemarks2] = React.useState("")
  const [subscriptionTime, setSubscriptionTime] = React.useState("")
  const [Datapassword, setDataPassword] = React.useState(true)
  const [VerifyTime, setTime] = React.useState("")
  const [UBTC, setUBTC] = React.useState(0)

  // const [Name, setName] = React.useState(FetchData.title.rendered)
  const dispatch = useDispatch()

  const [reload, setReload] = React.useState(false)

  const [AlertShow, setAlertShow] = React.useState(false)
  const [Alertcomp, setAlertcomp] = React.useState("")

  const AfterUserCreate = (id) => {
    const data = {
      "title": Title,
      "status": "publish",
      "author": id,
      "meta": {
        "user_role": "teacher",
        "user_profile_picture": ProfilePicture,
        "user_phone_number": PhoneNumber,
        "verification_status": VerificationStatus,
        "user_id": `${id}`,
        "user_name": userName,
        "user_email": userEmail,
        "user_verification_history": userVerificationHistory,
        "batch_id": "",
        "batch_name": "",
        "user_featured_exam": batchName,
        "user_featured_exam_slug": batchID,
        "user_featured_exam_main_slug":MainSlug,
        "number_of_exams": "",
        "class_code": Password,
      }
    }
    if (ID === "")
      AdsPOSTApi(data, slugs.db_slug_user_data)
        .then((res) => {
          setReload(false)
          setID(res.data.id)
          setAlertShow(true)
          setAlertcomp("Book Added Successfully")
          history.push(`${slugs.student_details}${res.data.slug}`)
        }).catch(err => {
          setReload(false)
          console.log(err)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
    else
      AdsPUTApi(data, slugs.db_slug_user_data, ID)
        .then((res) => {
          setReload(false)
          setAlertShow(true)
          setAlertcomp(`${Title} edited Successfully`)
        }).catch(err => {
          console.log(err)
          console.warn(err.response.data)
          console.log("Response Status: hello11", err.response.status);
          console.log("Response Headers:", err.response.headers);
          setReload(false)
          setAlertShow(true)
          setAlertcomp(err.response.data.message)
        })
  }
  const EditInfo = () => {
    setReload(true)

    if (ID == "") {
      const dataWithPassword = {
        "username": userName,
        "email": userEmail,
        "password": Password,
        "roles": "teacher"
      }
      AdsPOSTApi(dataWithPassword, "users")
        .then(res => {
          AfterUserCreate(res.data.id)
        })
        .catch(e =>
          console.log(e)
        )
    }
    else {
      if (Datapassword) {
        const dataWithPassword = {
          "password": Password,
          "roles": "teacher"
        }
        if (Login.userID !== userID)
          AdsPUTApi(dataWithPassword, `users`, `${userID}`)
            .then(res => {
              AfterUserCreate(userID)
            })
            .catch(e =>
              console.log(e)
            )
        else {
          setAlertShow(true)
          setAlertcomp("You are not allowed to cahnge owns password")
          setReload(false)
        }
      }
      else {
        const data = {
          "roles": UserRole
        }
        AdsPUTApi(data, `users`, `${userID}`)
          .then(res => {
            AfterUserCreate(userID)
          })
          .catch(e =>
            console.log(e)
          )
      }
    }
  }

  const dateChangePressed = (verifyTime) => {
    const date = new Date();

    const monthToday = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
    const yearToday = date.getUTCFullYear() < 9 ? `0${date.getUTCFullYear()}` : date.getUTCFullYear();

    const monthnew = (date.getMonth() + 1 + verifyTime) % 12;
    const month = monthnew < 9 ? `0${monthnew}` : `${monthnew}`
    const day = date.getDate() < 9 ? `0${date.getDate()}` : `${date.getDate()}`
    const year = date.getMonth() < (11 - verifyTime) ? `${date.getUTCFullYear()}` : `${date.getUTCFullYear() + 1}`
    setEditStartDate(`${yearToday}-${monthToday}-${day}`)
    setEditEndDate(`${year}-${month}-${day}`)

  }

  useEffect(() => {
    BatchList(slugs.db_slug_paid_exam, setExamArray1)
    BatchList(slugs.db_slug_chapter_wise_exam, setExamArray2)
  }, [])
  const BatchList = (slug, setArray) => {
    AdsGETApiAuth(
      { per_page: 1000, _fields: "id,slug,title,meta.exam_type" },
      slug
    )
      .then((res) => {
        setArray(res.data)
        if (slug == slugs.db_slug_paid_exam)
          setExamArray(res.data)
        setExamSelect(res.data)
      })
      .catch(e => {
        console.log(e)
      })
  }
  return (
    <div>{console.log(Login)}
      <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Teacher Information </center></h4>
          <table>
            <tbody>
              <tr>
                <td className="col-3">Name </td>
                <td className="col-6">
                  <div className="iinput-common">
                    {userName}
                  </div>
                  {/* <input className="input-common"
                    value={Title} placeholder="Name"
                    onChange={(event) => { setTitle(event.target.value) }}
                  /> */}
                </td>
              </tr>

              <tr>
                <td className="col-3">Profile Picture</td>
                <td className="col-6">
                  <ImageUpload
                    ImageUrl={ProfilePicture}
                    setImageUrl={setProfilePicture}
                  />
                </td>
              </tr>
              {/* <tr>
                <td className="col-3">Phone Number</td>
                <td className="col-6">
                  <input className="input-common"
                    value={PhoneNumber} placeholder="Phone Number"
                    onChange={(event) => { setPhoneNumber(event.target.value) }}
                  />
                </td>
              </tr> */}
              {ID == "" &&
                <tr>
                  <td className="col-3">User Name</td>
                  <td className="col-6">
                    <input className="input-common"
                      value={userName} placeholder="User Name"
                      onChange={(event) => { setUserName(event.target.value) }}
                    />
                  </td>
                </tr>
              }

              <tr>
                <td className="col-3">User Email</td>
                <td className="col-6">
                  {ID == "" ?
                    <input className="input-common"
                      value={userEmail} placeholder="User Email"
                      onChange={(event) => { setUserEmail(event.target.value) }}
                    />
                    :
                    <div className="input-common" >{userEmail}</div>
                  }
                </td>
              </tr>
              <tr>
                <td className="col-3">Class Code</td>
                <td className="col-6">
                  <input className="input-common"
                    value={Password} placeholder="Class Code"
                    onChange={(event) => { setPassword(event.target.value) }}
                  />

                </td>
              </tr>
              <tr>
                <td className="col-3">Select Exam </td>
                <td className="col-6"

                >
                  <div className="row">
                    <div className="col-8">
                      <div className="input-common"
                        onClick={() => {
                          setBatchModal(!batchModal)
                        }}>
                        {batchName}&nbsp;
                      </div>
                    </div>
                    <div className="col-4"
                      onClick={() => {
                        setBatchID("")
                        setBatchName("")
                      }}
                    >
                      Clear
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="row center m-2">
            <div className=" col-3" style={{ padding: "0 10px" }}>
              <div className="sign-in-button-4"
                onClick={() => {
                  EditInfo()
                }}
              >
                Update Teacher
              </div>
            </div>
          </div>
        </div>
      </div>
      {batchModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ maxHeight: "400px", margin: " 10px", overflowX: 'hidden', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setBatchModal(!batchModal)
                  }}
                >X</div>
                <div className="row" style={{ cursor: "pointer", border: "1px solid #999" }}>

                  <div className="row" style={{ width: "100%", justifyContent: "space-evenly", cursor: "pointer", border: "1px solid #999" }} >
                    <input className="col-3" style={{ margin: "1px 2px", padding: "3px 2px" }}
                      value={serachName} placeholder="Name"
                      onChange={(event) => {
                        setSearchName(event.target.value)
                        if (MainSlug == slugs.db_slug_chapter_wise_exam)
                          setExamArray(ExamArray2.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                        else
                          setExamArray(ExamArray1.filter(a => a.title.rendered.toLowerCase().includes(event.target.value.toLowerCase())))
                      }}
                    />

                    <div className={UBTC == 0 ? "examselectStudent" : "examunselectStudent"}
                      onClick={() => {
                        setUBTC(0)
                        setExamArray(ExamArray1)
                        setMainSlug(slugs.db_slug_paid_exam)
                      }}
                    >
                      UBT
                    </div>
                    <div className={UBTC == 1 ? "examselectStudent" : "examunselectStudent"} onClick={() => {
                      setUBTC(1)
                      setExamArray(ExamArray2)
                      setMainSlug(slugs.db_slug_chapter_wise_exam)
                    }} >
                      ChapterWise
                    </div>
                  </div>

                </div>
                <table>
                  <tbody>
                    {ExamArray.map((item, index) => (
                      <tr className="row bg-white shadow" key={index}
                        style={{ overflowX: "hidden", cursor: "pointer", border: "1px solid #999" }}
                        onClick={() => {
                          setBatchID(`${item.slug}`)
                          setBatchName(item.title.rendered)
                          setBatchModal(!batchModal)
                        }}
                      >
                        <td className="col-1">
                          {batchID !== item.id ?
                            <i className="fa fa-square-o" />
                            :
                            <i className="fa fa-check-square-o" />
                          }
                        </td>
                        <td className="col-2" >
                          {item.id}
                        </td>
                        <td className="col-7">
                          {item.title.rendered}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      }

      {/* <div className="row">
        <div className="col-sm-2 col-1"></div>
        <div className="col-sm-8 col-10 form-view mb-3">
          <h4><center>Verification History</center></h4>
          <table>
            <tbody>
              <tr>
                <td >Screenshot</td>
                <td >Start Date</td>
                <td >End Date</td>
                <td >Amount Paid</td>
                <td ></td>
              </tr>
              {Object.values(userVerificationHistory).map((item, index) => (
                <tr key={index}>
                  <td >
                    {item.verify_screenshot !== "" &&
                      <a href={item.verify_screenshot} target="_blank">
                        <img src={item.verify_screenshot} style={{ width: "20px", height: "20px" }} />
                      </a>
                    }
                  </td>
                  <td >{item.verify_start_date}</td>
                  <td >{item.verify_end_date}</td>
                  <td >{item.amount_paid}</td>
                  <td
                    onClick={() => {
                      setUserVerificationHistoryModal(true)
                      setEditScreenShot(item.verify_screenshot)
                      setEditStartDate(item.verify_start_date)
                      setEditEndDate(item.verify_end_date)
                      setEditAmountPaid(item.amount_paid)
                      setEditRemarks1(item.verification_remarks_1)
                      setEditRemarks2(item.verification_remarks_2)
                      setEditKey(Object.keys(userVerificationHistory)[index])
                    }}
                  >Edit</td>
                </tr>
              ))}
              <tr>
                <td colSpan={4}
                  onClick={() => {
                    setUserVerificationHistoryModal(true)
                    setEditScreenShot("")
                    setEditStartDate("")
                    setEditEndDate("")
                    setEditAmountPaid("")
                    setEditRemarks1("")
                    setEditRemarks2("")
                    setEditKey(`item-${Object.keys(userVerificationHistory).length}`)
                  }}
                >
                  <center><div style={{ width: "100px" }} className="sign-in-button-4"> New</div></center>
                </td>
              </tr>
            </tbody>
          </table>
        </div >
      </div >
      */}

      {userVerificationHistoryModal &&
        <section className="modal" >
          <div className="row">
            <div className="col-md-3 col-1"></div>
            <div className="col-md-6 col-10  shadow bg-white">
              <div style={{ margin: " 10px", overflowX: 'scroll', border: "1px solid #999", padding: "9px" }}>
                <div style={{ cursor: 'pointer', textAlign: 'right', padding: "0px 20px", fontWeight: "800" }}
                  onClick={() => {
                    setUserVerificationHistoryModal(false)
                    setEditKey("")
                  }}
                >X</div>
                <table>
                  <tr>
                    <td className="col-4"><b>SN : </b></td>
                    <td className="col-7">
                      {Number(editKey.replace("item-", "")) + 1}
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>ScreenShot : </b></td>
                    <td className="col-7">
                      <ImageUpload
                        ImageUrl={EditScreenShot}
                        setImageUrl={setEditScreenShot}
                      />
                      {EditScreenShot !== "" &&
                        <img src={EditScreenShot} style={{ height: "200px", width: "200px", objectFit: "contain" }} />
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Subscription : </b></td>
                    <td>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("3 months")
                          dateChangePressed(3)
                        }}
                      >
                        <div className={subscriptionTime == "3 months" ? "active-option" : "passive-option"}>
                          3 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("6 months")
                          dateChangePressed(6)
                        }}
                      >
                        <div className={subscriptionTime == "6 months" ? "active-option" : "passive-option"}>
                          6 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("1 year")
                          dateChangePressed(12)
                        }}
                      >
                        <div className={subscriptionTime == "1 year" ? "active-option" : "passive-option"}>
                          12 months
                        </div>
                      </button>
                      <button className="border-0 bg-transparent" style={{ margin: "3px 0" }}
                        onClick={() => {
                          setSubscriptionTime("Custom")
                        }}
                      >
                        <div className={subscriptionTime == "Custom" ? "active-option" : "passive-option"}>
                          Custom
                        </div>
                      </button>
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Start Date : </b></td>
                    <td className="col-7">
                      {subscriptionTime == "Custom" ?
                        <input type="date"
                          className="p-1 px-2 col-12"
                          value={EditStartDate}
                          onChange={(event) => {
                            setEditStartDate(event.target.value)
                          }}
                          placeholder="Start Date"
                        />
                        :
                        <div>
                          {EditStartDate}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>End Date : </b></td>
                    <td className="col-7">
                      {subscriptionTime == "Custom" ?
                        <input type="date"
                          className="p-1 px-2 col-12"
                          value={EditEndDate}
                          onChange={(event) => { setEditEndDate(event.target.value) }}
                          placeholder="End Date"
                        />
                        :
                        <div>
                          {EditEndDate}
                        </div>
                      }
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Paid Amount : </b></td>
                    <td className="col-7">
                      <input
                        className="p-1 px-2 col-12"
                        value={EditAmountPaid}
                        onChange={(event) => { setEditAmountPaid(event.target.value) }}
                        placeholder="Amount Paid"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="col-4"><b>Remarks : </b></td>
                    <td className="col-7">
                      <input
                        className="p-1 px-2 col-12"
                        value={EditRemarks1}
                        onChange={(event) => { setEditRemarks1(event.target.value) }}
                        placeholder="Remarks"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <center>
                        <div style={{ width: "30%" }} className="sign-in-button-1"
                          onClick={() => {
                            setUserVerificationHistory({
                              ...userVerificationHistory,
                              [editKey]: {
                                'verify_screenshot': EditScreenShot,
                                'verify_start_date': EditStartDate,
                                'verify_end_date': EditEndDate,
                                'amount_paid': EditAmountPaid,
                                'verification_remarks_1': EditRemarks1,
                                'verification_remarks_2': EditRemarks2,
                              }
                            }
                            )
                            setUserVerificationHistoryModal(false)
                          }}
                        >Done</div>
                      </center>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </section>
      }


      {
        reload &&
        <div className="modal" style={{ padding: "50vw" }}>
          <div className="dot-pulse"></div>
        </div>
      }
      {
        AlertShow &&
        <AlertComponent Alertcomp={Alertcomp} setAlertShow={setAlertShow} />
      }
    </div >
  )

}